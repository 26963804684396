import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"pageHeader my-12"},[_vm._v("Change Password")]),_c('div',[_c(VForm,{ref:"form",staticClass:"createPasswordForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-2"},[_c('AppFormField',{attrs:{"type":"password","outlined":"","dark":"","rules":_vm.formValidation.requiredRules(),"allowShowPassword":_vm.hide.currentPassword,"label":"Current Password"},model:{value:(_vm.formValues.oldPassword),callback:function ($$v) {_vm.$set(_vm.formValues, "oldPassword", $$v)},expression:"formValues.oldPassword"}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"6"}}),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-2"},[_c('AppFormField',{attrs:{"type":"password","outlined":"","dark":"","rules":_vm.formValidation
                  .passwordRules(_vm.formValues.password)
                  .concat(_vm.formValidation.requiredRules()),"allowShowPassword":_vm.hide.password,"label":"New Password"},model:{value:(_vm.formValues.password),callback:function ($$v) {_vm.$set(_vm.formValues, "password", $$v)},expression:"formValues.password"}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-2"},[_c('AppFormField',{attrs:{"type":"password","outlined":"","dark":"","rules":_vm.formValidation
                  .confirmPasswordRules(
                    _vm.formValues.password,
                    _vm.formValues.passwordConfirmation
                  )
                  .concat(_vm.formValidation.requiredRules()),"allowShowPassword":_vm.hide.confirmPassword,"label":"Re-type Password"},model:{value:(_vm.formValues.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formValues, "passwordConfirmation", $$v)},expression:"formValues.passwordConfirmation"}})],1)])],1)],1),_c('div',{staticClass:"text-center my-12 py-12"},[_c(VBtn,{staticClass:"btn1",class:_vm.$isMobile() ? 'w-100' : '',attrs:{"disabled":!_vm.valid || !_vm.fieldsNotNull},on:{"click":_vm.submit}},[_vm._v(" Change Password ")]),_c('div',{staticClass:"mt-5 mb-12"},[_c('a',{staticClass:"underlineBtn",on:{"click":_vm.back}},[_vm._v("Back")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }