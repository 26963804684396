<template>
  <div class="container">
    <h1 class="pageHeader my-12">Change Password</h1>
    <div>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        class="createPasswordForm"
      >
        <v-row no-gutters justify="center">
          <v-col cols="12" md="6">
            <div class="mx-2">
              <AppFormField
                type="password"
                outlined
                dark
                v-model="formValues.oldPassword"
                :rules="formValidation.requiredRules()"
                :allowShowPassword="hide.currentPassword"
                label="Current Password"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6"> </v-col>
          <v-col cols="12" md="6">
            <div class="mx-2">
              <AppFormField
                type="password"
                outlined
                dark
                :rules="
                  formValidation
                    .passwordRules(formValues.password)
                    .concat(formValidation.requiredRules())
                "
                :allowShowPassword="hide.password"
                v-model="formValues.password"
                label="New Password"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="mx-2">
              <AppFormField
                type="password"
                outlined
                dark
                :rules="
                  formValidation
                    .confirmPasswordRules(
                      formValues.password,
                      formValues.passwordConfirmation
                    )
                    .concat(formValidation.requiredRules())
                "
                :allowShowPassword="hide.confirmPassword"
                v-model="formValues.passwordConfirmation"
                label="Re-type Password"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <div class="text-center my-12 py-12">
        <v-btn
          class="btn1"
          :class="$isMobile() ? 'w-100' : ''"
          @click="submit"
          :disabled="!valid || !fieldsNotNull"
        >
          Change Password
        </v-btn>
        <div class="mt-5 mb-12">
          <a class="underlineBtn" @click="back">Back</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import { PROFILE } from '@/api';

  export default {
    name: 'ChangePassword',
    data: () => ({
      formValues: {
        oldPassword: null,
        password: null,
        passwordConfirmation: null
      },
      valid: false,
      formValidation: formValidationHelper,
      hide: {
        currentPassword: true,
        password: true,
        confirmPassword: true
      }
    }),
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      fieldsNotNull() {
        return Object.values(this.formValues).every((x) => !!x);
      }
    },

    methods: {
      async submit() {
        try {
          await PROFILE.changePassword({ ...this.formValues });
          this.$openModal('messageModal', 'Successfully changed password', {
            title: 'Ok',
            action: () => {
              this.$closeModal();
              this.back();
            }
          });
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      },
      back() {
        this.$router.push(
          this.me.role == 'talent' ? '/profile-info' : '/company-info'
        );
      }
    }
  };
</script>

<style></style>
